@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./GT-Eesti-Pro-Display-Light.eot');
  src: url('./GT-Eesti-Pro-Display-Light-2.woff') format('woff2'),
    url('./GT-Eesti-Pro-Display-Light.woff') format('woff'),
    url('./GT-Eesti-Pro-Display-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./GT-Eesti-Pro-Display-Regular.eot');
  src: url('./GT-Eesti-Pro-Display-Regular-2.woff') format('woff2'),
    url('./GT-Eesti-Pro-Display-Regular.woff') format('woff'),
    url('./GT-Eesti-Pro-Display-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./GT-Eesti-Pro-Display-Medium.eot');
  src: url('./GT-Eesti-Pro-Display-Medium-2.woff') format('woff2'),
    url('./GT-Eesti-Pro-Display-Medium.woff') format('woff'),
    url('./GT-Eesti-Pro-Display-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'GT-Eesti-Pro-Display';
  src: url('./GT-Eesti-Pro-Display-Bold.eot');
  src: url('./GT-Eesti-Pro-Display-Bold-2.woff') format('woff2'),
    url('./GT-Eesti-Pro-Display-Bold.woff') format('woff'),
    url('./GT-Eesti-Pro-Display-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
