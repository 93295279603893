@import './assets/fonts/styles.css';

.gdprsite-other * {
  box-sizing: border-box;
}

.cf::after {
  content: '';
  clear: both;
  display: table;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'GT-Eesti-Pro-Display', arial, sans-serif;
  font-size: 18px;
}

h1,
.h1 {
  font-size: 3.625em;
}
h2,
.h2 {
  font-size: 2.875em;
}
h3,
.h3 {
  font-size: 2.5625em;
}
h4,
.h4 {
  font-size: 2.25em;
}
h5,
.h5 {
  font-size: 1.8125em;
}

fieldset {
  padding: 0;
  border: 0;
  margin: 0;
}

.loose-text {
  line-height: 2em;
}

.wrapper {
  width: 100%;
  max-width: 75em;
  margin: auto;
}

.small-wrapper {
  width: 100%;
  max-width: 45em !important;
  margin: auto;
}

.full-width {
  width: 100% !important;
  max-width: 100% !important;
}

.white-text {
  color: white;
}

.right {
  float: right;
}

.left {
  float: left;
}

.text-center {
  text-align: center !important;
}

.text-left {
  text-align: left !important;
}

body p {
  max-width: 40em;
  margin-right: auto !important;
  margin-left: auto !important;
}

body .no-max-width-p p {
  max-width: none;
}

.max-width-50 {
  max-width: 50em !important;
}

.padding-top-small {
  padding-top: 1em;
}
.padding-bottom-small {
  padding-bottom: 1em;
}

.padding-top {
  padding-top: 2em;
}
.padding-bottom {
  padding-bottom: 2em;
}

.margin-top {
  margin-top: 2em !important;
}
.margin-top-small {
  margin-top: 1em !important;
}

.margin-top-large {
  margin-top: 3em !important;
}

.margin-bottom-large {
  margin-bottom: 3em !important;
}
.margin-right {
  margin-right: 2em;
}

.margin-left {
  margin-left: 2em !important;
}

.marginless {
  margin: 0 auto !important;
}

.margin-bottom-none {
  margin-bottom: 0;
}

.margin-bottom-small {
  margin-bottom: 1em !important;
}

.margin-bottom {
  margin-bottom: 2em !important;
}

.vertical-timeline-element-text {
  font-weight: normal !important;
  margin-bottom: 0;
}

.vertical-timeline-element-title {
  font-size: 18px !important;
  margin: 0 !important;
  font-weight: bold !important;
}

.vertical-timeline-element-icon {
  top: 13px !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element {
    margin: 2em 0 !important;
  }
  .vertical-timeline-element-content::before {
    top: 15px !important;
  }

  .vertical-timeline-element-icon {
    top: 0 !important;
  }
}

.vertical-timeline-element-content {
  border-radius: 0 !important;
}

.vertical-timeline-element-content::before {
  border: 15px solid transparent !important;
  border-right: 15px solid #fff !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element-content::before {
    border: 15px solid transparent !important;
    border-left: 15px solid #fff !important;
  }
}

.vertical-timeline-element.vertical-timeline-element--right
  .vertical-timeline-element-content::before,
.vertical-timeline-element:nth-child(even):not(.vertical-timeline-element--left)
  .vertical-timeline-element-content::before {
  border: 15px solid transparent !important;
  border-right: 15px solid #fff !important;
}

.vertical-timeline-element--laptop svg {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px !important;
  margin-left: -7px !important;
}

.vertical-timeline-element--envelope svg {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px !important;
  margin-left: -7px !important;
}

.vertical-timeline-element--garbagecan svg {
  width: 30px !important;
  height: 30px !important;
  margin-top: 5px !important;
  margin-left: -7px !important;
}

@media only screen and (min-width: 1170px) {
  .vertical-timeline-element--laptop svg {
    width: 40px !important;
    height: 40px !important;
    margin-left: -7px !important;
    margin-top: 8px !important;
  }

  .vertical-timeline-element--envelope svg {
    width: 40px !important;
    height: 40px !important;
    margin-left: -7px !important;
    margin-top: 8px !important;
  }

  .vertical-timeline-element--garbagecan svg {
    width: 40px !important;
    height: 40px !important;
    margin-left: -7px !important;
    margin-top: 8px !important;
  }
}

.vertical-timeline-element-content {
  border-top: 2px solid #79a0d4;
}

hr.thick {
  border-width: 0.5em;
  margin: 2.5em 0;
  border-color: #f7f7f7;
  border-style: solid;
}

hr.medium {
  border-width: 0.1em;
  margin: 1em 0;
  border-color: #f7f7f7;
  border-style: solid;
}

.user-panel {
  position: absolute;
  background-color: #79a0d4;
  padding: 1em;
  min-width: 20em;
  text-align: center;
  top: 3em;
  right: 0;
  z-index: 10;
}

.user-panel hr {
  margin-bottom: 1.33em;
}

.user-panel:after {
  content: '';
  position: absolute;
  top: 0;
  right: 2.5em;
  width: 0;
  height: 0;
  border: 0.625em solid transparent;
  border-bottom-color: #79a0d4;
  border-top: 0;
  margin-left: -0.625em;
  margin-top: -0.625em;
}

.user-panel a {
  text-decoration: none;
  color: white;
}

.open-panel {
  cursor: pointer;
}

.hidden {
  display: none;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
  animation-name: fadeInDown;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -10%, 0);
    transform: translate3d(0, -10%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
  animation-name: fadeOutDown;
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.active-lang {
  text-decoration: underline !important;
  font-weight: bold !important;
}

.timeline-date {
  font-weight: normal;
  display: block;
  color: #bebec2;
  font-size: 14px;
  margin-top: 3px;
}
@media only screen and (min-width: 1170px) {
  .timeline-date {
    float: right;
  }
}

footer a {
  text-decoration: none;
  color: #41414a;
}

footer a:hover {
  text-decoration: underline;
}
